import React, {useState, useEffect} from "react"
import {Col, Collapse, Container, Nav, Navbar, NavbarToggler, Row} from "reactstrap"
import {connect} from "react-redux"
import NavbarUser from "./NavbarUser"
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import starImg from '@assets/img/star.png'
const Navbars = props => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            autoDisplay: false
          },
          "google_translate_element"
        );
      };
     
      useEffect(() => {
        var addScript = document.createElement("script");
        addScript.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
      }, []);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <header id='main-header'>
            <Container>
                <Row>
                    <Col>
                        <Navbar bg="light" variant="light" expand="lg" className="custom-navbar">
                            <div id="google_translate_element"></div>
                            <div className="nav-bar-toggle d-lg-none" style={{marginLeft: '15px'}}>
                                <button type="button" className="toggle-menu-btn" onClick={toggle}>
                                    {!isOpen && <svg width="29" height="16" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 15C0 15.5523 0.447715 16 1 16H28C28.5523 16 29 15.5523 29 15C29 14.4477 28.5523 14 28 14H1C0.447716 14 0 14.4477 0 15Z" fill="currentColor"/>
                                        <path d="M0 8C0 8.55228 0.447715 9 1 9H28C28.5523 9 29 8.55228 29 8C29 7.44772 28.5523 7 28 7H1C0.447716 7 0 7.44772 0 8Z" fill="currentColor"/>
                                        <path d="M0 1C0 1.55228 0.447715 2 1 2H28C28.5523 2 29 1.55228 29 1C29 0.447715 28.5523 0 28 0H1C0.447716 0 0 0.447715 0 1Z" fill="currentColor"/>
                                    </svg>}

                                    {isOpen && <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.82868 12.728L13.314 4.24268" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M4.82868 4.24262L13.314 12.7279" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    }

                                </button>
                            </div>
                            {props.user.email != '' && (
                                <NavbarUser
                                    confirm={props.confirm}
                                    userName={`${props.user.fname}`}
                                    loggedInWith={
                                        props.user !== undefined
                                            ? props.loggedInWith
                                            : null
                                    }
                                />
                            )}
                            <Collapse isOpen={isOpen} navbar>
                                <Nav className="mr-auto ml-0" style={{marginLeft: '0', marginRight: 'auto'}}>
                                    <li>
                                        <NavLink to='/dashboard' activeClassName='in-page'>
                                            <img src={starImg} alt="star" style={{maxHeight: '20px', width: 'auto', marginRight: '5px'}}/> 
                                            {t("dashboard.Dashboard")}
                                        </NavLink>
                                    </li>
                                </Nav>
                            </Collapse>
                        </Navbar>
                    </Col>
                </Row>
            </Container>

        </header>
    )
}

const mapStateToProps = state => {
    return {
        user: state.authorizedUser.user,
    }
}

export default connect(mapStateToProps, {})(Navbars);
